import * as THREE from "three";

function main() {
  const scene = new THREE.Scene();
  scene.fog = new THREE.Fog(0x111111, 0.01);

  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.y = 5;
  camera.rotateX(-0.2);

  const renderer = new THREE.WebGLRenderer({ antialias: true });

  renderer.setClearColor("#111111");
  renderer.setSize(window.innerWidth, window.innerHeight);

  document.body.appendChild(renderer.domElement);

  const grid = new THREE.GridHelper(500, 500, 0x222222, 0x222222);

  (grid.material as THREE.LineBasicMaterial).opacity = 0.5;

  // Add cube to Scene
  scene.add(grid);

  // Resize
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    render();
  }
  window.addEventListener("resize", onWindowResize, false);

  // Render Loop
  const render = function () {
    // requestAnimationFrame(render);
    // Render the scene
    renderer.render(scene, camera);
  };

  render();
}

main();
